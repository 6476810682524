//Suppliers Archive Page
import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/scss/post-types/supplier.scss';
// import externalIcon from "../assets/images/external.svg";

const Suppliers = ({ data }) => {

  const name = data.allWpSupplier.nodes.sort((a, b) => a.title.localeCompare(b.title))

  return (
      <Layout>
        <main className="suppliers">
        <Container fluid="xl">
          <h1>Suppliers</h1>
          <Row>
            {name.map((node, index) => (
              <Col md={4} lg={3} key={index} className="supplier">
                <Link to={`/supplier/${node.slug}`}>
                  {node.title && <h5>{node.title}</h5>}
                </Link>
                {/* {node.supplier.location && <p key={index}>{node.supplier.location}</p>}
                {node.supplier.phoneNumber && <a href={`tel:${node.supplier.phoneNumber}`} className="telephone">{node.supplier.phoneNumber}</a>} */}
                <ul className="products">
                {node.supplier.products && node.supplier.products.slice(0, 3).map(({product, index}) => (
                  <li key={index}>{product}</li>
                ))}
                </ul>
                {/* {node.supplier.website && <a href={node.supplier.website} className="website" target="_blank" rel="noreferrer">Visit Website</a>} */}

              </Col>
            ))}
            
          </Row>
        </Container>
        </main>
      </Layout>
  )

}
export default Suppliers

export const pageQuery = graphql`
  query {
    allWpSupplier(sort: { fields: [date] }) {
      nodes {
        title
        slug
        supplier {
          phoneNumber
          location
          website
          products {
            product
          }
        }
      }
    }
  }
`
